.clients {
    &ContentWrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 20px 20px;
    }
    &__block {
        object-fit: scale-down;
        width: calc((100% - 20px) / 2);
        display: flex;
        justify-content: center;
        align-items: center;
        & > img {
            width: 100%;
            object-fit: scale-down;
        }
    }
}

@media (min-width: 1056px) {
    .clients {
        &__block {
            width: calc((100% - 60px) / 4);
        }
    }
}
