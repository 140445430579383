.reviews {
    &BottomWrapper {
        margin-top: 30px;
    }
    &ContentWrapper {
        display: flex;
        flex-direction: column;
    }
    &__block {
        position: relative;
        height: 227px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: 1px solid #c6c6c6;
        padding: 30px 13px;
        margin-top: -1px;
        margin-left: -1px;
        &__text {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }
        &__title {
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
        }
    }
    &__button {
        max-width: 100%;
        width: 100%;
        text-align: center;
        justify-content: center;
        padding-right: 15px;
    }
    &__text {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        margin-bottom: 13px;
    }
}

@media (min-width: 1056px) {
    .reviews {
        &BottomWrapper {
            width: calc((100% / 3) - 1px);
            padding-left: 10px;
            margin: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }
        &ContentWrapper {
            flex-direction: row;
            flex-wrap: wrap;
        }
        &__block {
            height: 218px;
            width: calc(100% / 3);
        }
        &__button {
            width: 148px;
        }
    }
}
