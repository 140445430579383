.header__navigation {
    display: none;
}

@media (min-width: 1280px) {
    .header__navigation {
        display: flex;
        flex-direction: row;
        height: 100%;
    }
}
