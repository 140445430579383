.mainBlock {
    display: flex;
    flex-direction: column;
    color: var(#161616);
    padding: 50px 16px 100px;
    &BtnsWrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-bottom: 30px;
    }

    &__button {
        max-width: 100%;
        width: 100%;
        text-align: center;
        justify-content: center;
        padding-right: 15px;
    }

    &__img {
        min-height: 180px;
        aspect-ratio: 1 / 0.625;
        object-fit: cover;
    }

    &__text > p {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        margin-bottom: 30px;
    }

    &__title {
        font-size: 32px;
        font-style: normal;
        font-weight: 300;
        line-height: 40px;
        margin-bottom: 60px;
    }
}

@media (min-width: 1056px) {
    .mainBlock {
        padding: 56px 32px 60px;
        flex-direction: row;
        justify-content: space-between;
        &BottomWrapper {
            display: flex;
            flex-direction: column;
            gap: 30px;
        }
        &BtnsWrapper {
            display: flex;
            flex-direction: row;
            gap: 33px;
            padding-bottom: 0px;
        }
        &LeftWrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: calc(50% - 82px);
        }
        &__button {
            max-width: fit-content;
            width: fit-content;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
        }
        &__img {
            aspect-ratio: 1 / 1;
            width: 50%;
        }
        &__text {
            margin-bottom: 0;
        }
        &__title {
            font-size: 42px;
            line-height: 50px;
        }
    }
}

@media (min-width: 1584px) {
    .mainBlock {
        padding: 56px 32px 80px;
        &BottomWrapper {
            gap: 40px;
        }
        &__text {
            font-size: 24px;
            line-height: 32px;
        }
        &__title {
            font-size: 60px;
            line-height: 70px;
        }
    }
}
