.ourProgress {
    &__number {
        font-size: 42px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    &__text {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }
    &__title {
        margin-bottom: 30px;
    }
}

@media (min-width: 1056px) {
    .ourProgress {
        &Block {
            width: 33%;
        }
        &Content {
            display: flex;
            justify-content: space-between;
        }
        &__number {
            font-size: 54px;
        }
        &__title {
            width: 380px;
        }
    }
}

@media (min-width: 1568px) {
    .ourProgress {
        &__title {
            width: 550px;
            margin-bottom: 60px;
        }
        &__number {
            font-size: 76px;
            line-height: 86px;
        }
    }
}
