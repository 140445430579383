.finishedProjectPhotos {
    &ContentWrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px 10px;
    }
    &__img {
        aspect-ratio: 0.5/0.625;
        object-fit: cover;
        width: calc(50% - 5px);
        &-wide {
            aspect-ratio: 1/0.625;
            width: 100%;
        }
    }
}

@media (min-width: 1056px) {
    .finishedProjectPhotos {
        &__img {
            aspect-ratio: 1/1;
            width: calc(33.33333% - 6.66667px);
            &-wide {
                aspect-ratio: 2/1;
                height: auto;
                width: calc(66.66667% - 3.33333px);
            }
        }
    }
}
