.ourProjects {
    &ContentWrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 10px 10px;
    }

    &__button {
        max-width: 100%;
        width: 100%;
        text-align: center;
        justify-content: center;
        padding-right: 15px;
    }

    &__img {
        object-fit: cover;
        height: auto;
        &-1 {
            width: 100%;
            aspect-ratio: 2 / 1;
        }
        &-2 {
            aspect-ratio: 1 / 1;
            width: calc((100% - 10px) / 2);
        }
        &-3 {
            aspect-ratio: 1 / 1;
            width: calc((100% - 10px) / 2);
        }
        &-4 {
            aspect-ratio: 2 / 1;
            width: 100%;
        }
    }
    &__text {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        width: 75%;
        margin-bottom: 13px;
    }
}

@media (min-width: 1056px) {
    .ourProjects {
        &BottomWrapper {
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            width: calc((100% - 20px) / 3);
        }
        &__button {
            width: 148px;
        }
        &__img {
            object-fit: cover;
            &-1 {
                width: calc(((100% - 20px) / 3 * 2) + 10px);
            }
            &-2 {
                width: calc((100% - 20px) / 3);
            }
            &-3,
            &-4 {
                width: calc((100% - 20px) / 3);
            }
        }
        &__text {
            width: 100%;
        }
    }
}
