.tags {
    display: flex;
    flex-wrap: wrap;
    gap: 30px 50px;
    &__block {
        padding: 10px 20px;
        color: var(--tag-blue-text, #0043ce);
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.32px;
        background: var(--tag-blue-background, #d0e2ff);
        border-radius: 1000px;
        text-align: center;
        width: calc((100% - 50px) / 2);
    }
}

@media (min-width: 1056px) {
    .tags {
        gap: 30px 40px;
        &__block {
            width: calc((100% - 160px) / 5);
        }
    }
}
