.aboutFull {
    &ContentWrapper {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
    &InfoWrapper {
        color: var(--gray-100, #161616);
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    &__intro {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 10px;
    }
    &__img {
        min-height: 180px;
        aspect-ratio: 1/0.625;
        object-fit: cover;
    }
}

@media (min-width: 1056px) {
    .aboutFull {
        &InfoWrapper {
            flex-direction: row;
            flex-wrap: wrap;
            gap: 20px 32px;
        }
        &IntroWrapper {
            width: 100%;
        }
        &__intro {
            width: 479px;
        }
        &__text {
            width: calc((100% - 32px) / 2);
            &-1 {
                order: 0;
            }
            &-2 {
                order: 2;
            }
            &-3 {
                order: 1;
            }
            &-4 {
                order: 3;
            }
        }
    }
}

@media (min-width: 1568px) {
    .aboutFull {
        &ContentWrapper {
            flex-direction: row;
            gap: 20px;
        }
        &InfoWrapper {
            flex-direction: column;
            gap: 20px;
            width: calc((100% - 20px) / 3 * 2);
        }
        &IntroWrapper {
            display: none;
        }
        &__img {
            width: calc((100% - 20px) / 3);
            aspect-ratio: 0.9/1;
        }
        &__text {
            width: 100%;
        }
    }
}
